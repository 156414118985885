import {
  BrickElement,
  defineCustomElement,
  type EventListenerObject,
} from '@amedia/brick-template';

import '@amedia/brick-icon';

import { brickButtonTemplate, type BrickButtonTemplate } from './template';
import { componentStyle } from './button-styles';
import type { BrickButtonData } from './types';
import { camelCase } from './utils';

@defineCustomElement({
  selector: 'brick-button-v7',
})
export class BrickButton extends BrickElement implements BrickButtonTemplate {
  dataAddclasses?: string;
  dataAdplabel?: string;
  dataAdpvalue?: string;
  dataAriaLabel?: string;
  dataAriaLabelledby?: string;
  dataAriaDescribedby?: string;
  dataAriaControls?: string;
  dataAriaExpanded?: string;
  dataAriaPressed?: string;
  dataDisabled?: string;
  dataIconid?: string;
  dataIconplacement?: string;
  dataIcontext?: string;
  dataLinkto?: string;
  dataSize?: string;
  dataTarget?: string;
  dataAs?: string;
  dataVersion?: string;
  inlineStyles?: boolean | undefined;

  #element: HTMLButtonElement | HTMLAnchorElement | null = null;
  #onclick!: ((this: GlobalEventHandlers, ev: MouseEvent) => void) | null;

  constructor(data: Partial<BrickButtonData> = {}) {
    super();
    this.button_data = data;
  }

  async connectedCallback() {
    if (!this.isRendered) {
      this.classList.add(`${componentStyle}`);
    }
    super.connectedCallback();

    this.#element = this.querySelector('button') || this.querySelector('a');
  }

  set button_data(data: Partial<BrickButtonData>) {
    for (const [key, value] of Object.entries(data)) {
      if (BrickButton.mirroredProps.includes(key)) {
        this.dataset[key] = value;
      }
    }
  }

  get button_data(): BrickButtonData {
    return BrickButton.mirroredProps.reduce((acc, key) => {
      acc[camelCase(key)] = this[camelCase(key)] ?? this.dataset[key] ?? '';
      return acc;
    }, {} as BrickButtonData);
  }

  static get mirroredProps() {
    return [
      'data-addclasses',
      'data-adplabel',
      'data-adpvalue',
      'data-aria-label',
      'data-aria-labelledby',
      'data-aria-describedby',
      'data-aria-controls',
      'data-aria-expanded',
      'data-aria-pressed',
      'data-disabled',
      'data-iconid',
      'data-iconplacement',
      'data-icontext',
      'data-linkto',
      'data-label',
      'data-size',
      'data-target',
      'data-version',
      'data-as',
    ];
  }

  static get observedAttributes() {
    return [
      'data-aria-label',
      'data-aria-labelledby',
      'data-aria-describedby',
      'data-aria-controls',
      'data-aria-expanded',
      'data-aria-pressed',
    ];
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name && oldValue === newValue) return;
    if (name.startsWith('data-aria')) {
      this.data ??= {};
      this.data[camelCase(name)] = newValue;
      this.#element?.setAttribute(name.replace('data-', ''), newValue);
    }
  }

  get eventListeners(): EventListenerObject[] {
    if (this.onClick) {
      return [
        {
          selector: 'button',
          action: 'click',
          listener: this.onClick.bind(this),
        },
      ];
    }
    return [];
  }

  get HTML() {
    return brickButtonTemplate(this.button_data);
  }

  set onClick(callback) {
    this.#onclick = callback;
  }

  get onClick() {
    return this.#onclick;
  }

  focus(options?: FocusOptions | undefined): void {
    this.#element?.focus(options);
  }
}
